import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { RoleModel } from '../../model/roles/role.model';
import { User } from 'src/app/model/user';


@Injectable({
  providedIn: 'root'
})
export class RoleService {
  mainUrl = '/api/admin/role';
  urlGetRole = '/get-all-role';
  urlGetRoleName = '/get-all-role-name';
  urlGetAttributesId = '/get-attributes-id';
  urlAddRole = '/create-role';
  urlGetRoleId = '/get-role-id';
  urlDeleteAttribute = '/delete-attribute';
  urlAddAttributes = '/add-attribute';
  urlCheckexisRoleName = '/check-rolename-exist';
  urlEdit = '/add-Role-Attribute';
  urlListAttributeByUser = '/get-attribute-by-user';
  user: User;

  constructor(private http: HttpClient) {
  }
  getAllRole(): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlGetRole}`).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }
  getAllRoleName(): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlGetRoleName}`).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }

  getAttributesIdByRoleName(roleName: string): Observable<any> {
    const roleNames = new HttpParams().set('roleName', roleName);
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlGetAttributesId}`, { params: roleNames }).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }
  addNewRole(roleName: string): Observable<any> {
    const roleNames = new HttpParams().set('roleName', roleName);
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlAddRole}`, { params: roleNames }).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }

  checkIfRoleNameExists(roleName: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlCheckexisRoleName}`, { params: { roleName } });
  }

  getRolesAttributeForEdit(list: any[]): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlEdit}`, { params: { list } });
  }

  getListAttributeUser(_this: any): void {
    const user = localStorage.getItem('USER_INFO')
    this.user = JSON.parse(user);
    const list: any[] = [];
    this.user.usersRoles.forEach((element: any) => {
      list.push(element.roleId);
    });
    this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlListAttributeByUser}`, { params: { list } }).subscribe((res: any) => {
      this.checkAttribute(res, _this);
    });
  }

  listAction = {
    USER_SHOW: 'userShow',
    USER_UPDATE: 'userUpdate',
    ROLE_SHOW: 'roleShow',
    ROLE_UPDATE: "roleUpdate",
    PUBLISHER_SHOW: 'publisherShow',
    PUBLISHER_UPDATE: 'publisherUpdate',
    CAMPAIGN_SHOW: 'campaignShow',
    CAMPAIGN_UPDATE: 'campaignUpdate'
  };

  static roleAttributeUser = {
    userShow: false,
    userUpdate: false,
    roleShow: false,
    roleUpdate: false,
    publisherShow: false,
    publisherUpdate: false,
    campaignShow: false,
    campaignUpdate: false
  }
  checkAttribute(listAttribute: any[], _this: any): any {
    listAttribute.filter( a => a.isActive == 1).forEach(attribute => {
      _this.roleAttribute[this.listAction[attribute.attributeCode]] = true;
    }); 
  }

}
