import { Component, OnInit } from '@angular/core';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors } from '@angular/forms';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { UserInfo } from '../../../../model/user-info/user-info.model';
import { UserInfoService } from '../../../../services/user-info/user-info.service';
import { RoleService } from '../../../../services/role/role.service';
import { User } from '../../../../model/user';
import { Constants } from '../../../../theme/shared/common/constants';
import { UserService } from '../../../../services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppParamModel } from '../../../../model/app-param/app-param.model';
import { isTemplateRef, NzModalService } from 'ng-zorro-antd';
import { isBuffer } from 'util';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  userType: string;
  userDataSearch: UserInfo = {
    username: '',
    email: '',
    phoneNumber: '',
    roleId: [],
    status: null,
    userLoginType: '',
    userLoginId: null,
    pageSize: 10,
    activePage: 1
  };
  roleAttribute = { ...RoleService.roleAttributeUser };
  allRole: any[] = [];
  isVisible: boolean;
  userInfo: User;
  total: number;
  userInfoListOrigin: User[] = [];
  userInfoList: UserInfo[] = [];
  size: NzButtonSize = 'default';
  listOfData: UserInfo[] = [];
  isEdit: boolean;
  userEdit: User;
  isVisibleChangeStatus = false;
  isConfirmLoading = false;
  selectUser: any;


  constructor(private fb: FormBuilder,
    private i18n: NzI18nService,
    private userInfoService: UserInfoService,
    private userService: UserService,
    private roleService: RoleService,
    private modal: NzModalService,
    private notification: NzNotificationService) {

  }

  ngOnInit(): void {
    this.userAuthorization();
    this.getAllRoleToLoadCombobox();
    this.getTpyeAndIdUser();
    this.onSearch();
  }
  showModalEdit(user: User): void {
    this.isEdit = true;
    if (this.isEdit) {
      this.findUserById(user.userId);
    }
  }
  showModal(): void {
    this.isVisible = true;
  }

  showPopupChangeStatus(data): void {
    this.selectUser = data;
    this.isVisibleChangeStatus = true;
  }
  handleConfirm(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.userInfoService.updateStatus(this.selectUser).subscribe(next => {
        const findItem = this.userInfoListOrigin.find(x => x.userId === this.selectUser.userId);
        if (findItem.status === 0) {
          findItem.status = 1;
        }
        else {
          findItem.status = 0;
        }
        this.notification.success('Change status user complete.', '');
      });
      this.isVisibleChangeStatus = false;
      this.isConfirmLoading = false;
    }, 1000);
  }
  handleCancelPopup(): void {
    this.isVisibleChangeStatus = false;
  }

  findUserById(userId: number) {
    this.userService.findUserById(userId.toString()).subscribe(
      data => {
        this.userEdit = data.responseData;
        this.isVisible = true;
      }, err => {
        this.notification.create(
          'error',
          'An error occurred, please try again later!',
          ''
        );
      }, () => { });
  }
  getAllRoleToLoadCombobox() {
    this.roleService.getAllRole().subscribe(next => {
      this.allRole = next.responseData;
    });
  }

  getTpyeAndIdUser() {
    const userInfoTemp = localStorage.getItem('USER_INFO');
    this.userInfo = JSON.parse(userInfoTemp);
    this.userType = this.userInfo.userType;
  }

  resetEdit($event: any) {
    this.isEdit = $event;
    this.isVisible = $event;
  }
  changeDataEdit(data) {
    this.userDataSearch.userLoginType = this.userInfo.userType;
    this.userDataSearch.userLoginId = this.userInfo.userId;
    this.userInfoService.searchUser(this.userDataSearch).subscribe((next: any) => {
      if(next.responseCode === Constants.RESPONSE_STATUS_SUCCESS){
        let findEditUser = next.responseData.find(x => x.userId === data.userId);
        let findIndexEditUser = this.userInfoListOrigin.findIndex(item => item.userId === data.userId);
        this.userInfoListOrigin[findIndexEditUser].nameRole = findEditUser.nameRole;
        this.userInfoListOrigin[findIndexEditUser].status = findEditUser.status;
      }
    });
  }
  isClose($event: any) {
    this.isVisible = $event;
  }

  onSearch() {
    if (!this.userDataSearch.username) {
      this.userDataSearch.username = '';
    }
    if (this.userDataSearch.email) {
      const endValue = this.userDataSearch.email.trim();
      this.userDataSearch.email = endValue;
    }
    this.userDataSearch.userLoginType = this.userInfo.userType;
    this.userDataSearch.userLoginId = this.userInfo.userId;
    this.callApiSearch(this.userDataSearch);
  }

  callApiSearch(dataSearch: UserInfo) {
    this.userInfoListOrigin = [];
    this.userInfoService.searchUser(dataSearch).subscribe((next: any) => {
      if (next.responseCode === Constants.RESPONSE_STATUS_SUCCESS) {
        next.responseData.data.forEach(item => {
          this.userInfoListOrigin.push(item);
        });
        this.userInfoListOrigin = [...this.userInfoListOrigin];
        this.total = next.responseData.total;
      }
    });
  }
  preventSpace() {
    const usernameSpace = this.userDataSearch.username.toString().trim();
    const emailSpace = this.userDataSearch.email.toString().trim();
    const phoneSpace = this.userDataSearch.phoneNumber.toString().trim();
    this.userDataSearch.username = usernameSpace;
    this.userDataSearch.email = emailSpace;
    this.userDataSearch.phoneNumber = phoneSpace;
  }

  userAuthorization() {
    this.roleService.getListAttributeUser(this);
  }
  onPageIndexChange($event) {
    this.userDataSearch.activePage = $event;
    this.callApiSearch(this.userDataSearch);
  }
}
