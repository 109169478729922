import {AbstractControl, FormControl, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
// import moment from 'moment';

export class CustomValidator implements Validator {

  private static convertStringToDAte(dateStr: string): Date {
    const date = dateStr.substr(0, 2);
    const month = dateStr.substr(3, 2);
    const year = dateStr.substr(6, 4);
    return new Date(Number(year), Number(month), Number(date));
  }


  // static checkAgeRange(compareAge: number): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     if (control.value) {
  //       let start = null;
  //       if (control.value instanceof Date) {
  //         start = control.value;
  //       } else if (typeof control.value === 'string') {
  //         start = this.convertStringToDAte(control.value);
  //       }
  //       const age = moment().diff(start, 'years');
  //       return age >= compareAge ? null : {checkAgeLessThan15: true};
  //     }
  //   };
  // }

  static validateNoFullSpace(c: FormControl): { [key: string]: any } | null {
    return (c.value === undefined || c.value === null || c.value.trim() === '') ? {
      customRequired: {
        valid: false
      }
    } : null;
  }

  static phoneNumberValidator(control: AbstractControl): ValidationErrors {
    if (control.value && control.value.trim().length > 0 && !(control.value as string).match('(03|07|08|84|09|01[2|6|8|9]|05[6|8|9])+([0-9]{8})')) {
      return {phoneNumberValidator: true};
    }
    return null;
  }
  static phoneNumberminLength(control: AbstractControl): ValidationErrors {
    if (control.value && control.value.trim().length > 0 && control.value.length < 9) {
      return {minLengthCustom: true};
    }
    return null;
  }
  static usernameMinlength(control: AbstractControl): ValidationErrors {
    if (control.value && control.value.trim().length > 0 && control.value.length < 6) {
      return {minLengthCustom: true};
    }
    return null;
  }
  static passwordMinlength(control: AbstractControl): ValidationErrors {
    if (control.value && control.value.trim().length > 0 && control.value.length < 8) {
      return {minLengthCustom: true};
    }
    return null;
  }

  static removeAccents(str): string {
    return str.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd').replace(/Đ/g, 'D').toUpperCase();
  }

  static embossedNameValidator(control: AbstractControl): ValidationErrors {
    if (control.value && !(control.value as string).match('^(?:[A-Z]+ ?){1,3}$')) {
      return {embossedNameValidator: true};
    }
    return null;
  }

  static convertDate(inputFormat) {
    function pad(s) {
      return (s < 10) ? '0' + s : s;
    }

    const d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
  }

  static toDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return new Date(year, month - 1, day);
  }

  registerOnValidatorChange(fn: () => void): void {
  }


  validate(control: AbstractControl): ValidationErrors | null {
    return undefined;
  }
}
