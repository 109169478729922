import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {LoginComponent} from "./auth/container/login/login.component";
import {AuthGuard} from "./auth/guards/auth.guard";
import {AffiliateGuard} from "./auth/guards/affiliate.guard";
import {NotFoundComponent} from "./theme/shared/components/not-found/not-found.component";
import {AdminGuard} from "./auth/guards/admin.guard";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/login'},
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard/analytics',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./affiliate-client/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'page',
        loadChildren: () => import('./affiliate-client/pages/pages.module').then(module => module.PagesModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin-config/admin-config.module').then(module => module.AdminConfigModule),
        canLoad: [AdminGuard]
      }
    ],
    canActivate: [AffiliateGuard],
    canLoad: [AffiliateGuard]
  },
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
