import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../auth-services/auth.service";
import {Constants} from "../../theme/shared/common/constants";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate() {
    const userInfo = localStorage.getItem('USER_INFO');
    const isLogin = this.authService.isLoggedIn();
    if (isLogin && userInfo) {
      this.router.navigate(['/dashboard/analytics']);
    }
    return !isLogin;
  }

}
