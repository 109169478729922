import { Component, DoCheck, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { GradientConfig } from '../../../../../app-config';
import { AuthService } from '../../../../../auth/auth-services/auth.service';
import { User } from '../../../../../model/user';
import { NzModalService } from 'ng-zorro-antd';
import { UserInfoComponent } from 'src/app/affiliate-client/pages/user-management/user-info/user-info.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig, AuthService],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;
  isVisible: boolean;
  isEdit: boolean;
  userEdit: User;
  userInfo: User = {
    username: 'Username'
  };
  myProfile = false;
  showUserInfo = false;
  getLocalInfo: any = {};
  constructor(
    public authService: AuthService,
    private modal: NzModalService,
    private userService: UserService
  ) {
    this.visibleUserList = false;
    this.chatMessage = false;

    this.gradientConfig = GradientConfig.config;
  }

  ngOnInit() {
    const userInfoStr = localStorage.getItem('USER_INFO');
    console.log('userInfoStr');
    if (userInfoStr) {
      this.getLocalInfo = JSON.parse(userInfoStr);
      this.userInfo = this.getLocalInfo;
      this.userService.findUserById(this.getLocalInfo.userId.toString()).subscribe((res: any) => {
        this.userInfo = res.responseData;
      });
    }

    console.log(this.userInfo);

  }



  onChatToggle(friendID) {
    this.friendId = friendID;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('elite-rtl')) {
      this.gradientConfig['rtl-layout'] = true;
    } else {
      this.gradientConfig['rtl-layout'] = false;
    }
  }

  logOut() {
    this.authService.logOutDemo();
  }
  resetEdit($event: any) {
    this.isEdit = $event;
    this.isVisible = $event;
  }
  isClose($event: any) {
    this.isVisible = $event;
    this.myProfile = $event;
    this.showUserInfo = $event;
    if(!event){
      this.userService.findUserById(this.getLocalInfo.userId.toString()).subscribe((res: any) => {
        this.userInfo = res.responseData;
      });
    }

  }
  showModal(data?: any) {
    data = this.userInfo;
    const modal = this.modal.create({
      nzTitle: "Edit profiles",
      nzContent: UserInfoComponent,
      nzComponentParams: {
        user: data
      },
      nzWidth: 700,
      nzFooter: [],
    });
    modal.afterClose.subscribe(() => {
      setTimeout(() => {
        this.userService.findUserById(this.userInfo.userId.toString()).subscribe((res: any) => {
          this.userInfo = res.responseData;
        });
      }, 3000);
    });
  }
  showMyProfile() {
    this.myProfile = true;
  }
  showModalUserInfo() {
    this.showUserInfo = true;
  }

}
