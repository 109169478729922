import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from "./guards/auth.guard";
import {AuthService} from "./auth-services/auth.service";
import {AffiliateGuard} from "./guards/affiliate.guard";
import {TokenInterceptor} from "./token.interceptor";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {LoginComponent} from "./container/login/login.component";

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    AffiliateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {
}
