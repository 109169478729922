<div class="row user-management">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-4">
        <app-card [hidHeader]="true" [options]="false">
          <form class="form-group" (ngSubmit)="onSearch()">
            <div class="mt-3">
              <label for="username">Username</label>
              <input (blur)="preventSpace()" type="text" nz-input id="username" name="username"
                [(ngModel)]="userDataSearch.username">
            </div>
            <div class="mt-3">
              <label for="email">Email</label>
              <input (blur)="preventSpace()" type="text" nz-input id="email" name="email"
                [(ngModel)]="userDataSearch.email">
            </div>
            <div class="mt-3">
              <label for="searchPhone">Phone number</label>
              <input appNumberic maxlength="11" (blur)="preventSpace()" (keydown.space)="$event.preventDefault()"
                type="text" nz-input id="searchPhone" name="searchPhone" [(ngModel)]="userDataSearch.phoneNumber">
            </div>
            <div class="mt-3">
              <label for="searchRole">Role</label>
              <nz-select id="searchRole" class="w-100 ng-select" name="searchRole" nzPlaceHolder="Select Role"
                nzMode="multiple" nzAllowClear [(ngModel)]="userDataSearch.roleId">
                <nz-option *ngFor="let role of allRole" [nzValue]="role['roleId']" [nzLabel]="role['roleName']">
                </nz-option>
              </nz-select>
            </div>
            <div class="mt-3">
              <label for="searchStatus">Status</label>
              <nz-select id="searchStatus" class="w-100" nzAllowClear name="searchStatus" nzPlaceHolder="Select status"
                [(ngModel)]="userDataSearch.status">
                <nz-option nzValue="1" nzLabel="Active"></nz-option>
                <nz-option nzValue="0" nzLabel="Inactive"></nz-option>
              </nz-select>
            </div>
            <div class="mt-4 d-flex">
              <button class="btn btn-primary">Search</button>
            </div>
          </form>
        </app-card>
      </div>
      <div class="col-md-8">
        <app-card [hidHeader]="true" [options]="false">
          <div class="mt-3 mb-4">
            <button [disabled]="!roleAttribute.userUpdate || userInfo.userType == 'ADV' || !roleAttribute.userShow" (click)="showModal()" nz-button
              nzType="primary" [nzSize]="size"><i class="fa fa-plus"></i></button>
          </div>
          <div class="table-responsive" *ngIf="roleAttribute.userShow">
            <nz-table [nzPageSize]="10" nzBordered #basicTable [nzData]="userInfoListOrigin" nzTableLayout="fixed" [nzShowPagination]="false">
              <thead>
                <tr>
                  <th nzWidth="15%" nzAlign="center">Username</th>
                  <th nzWidth="15%" nzAlign="center">Role</th>
                  <th nzWidth="15%" nzAlign="center">Phone number</th>
                  <th nzWidth="20%" nzAlign="center">Email</th>
                  <th nzWidth="15%" nzAlign="center">Created at</th>
                  <th nzWidth="10%" nzAlign="center">Active</th>
                  <th nzWidth="10%" nzAlign="center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of basicTable.data">
                  <td nzEllipsis nz-tooltip nzTooltipTitle="{{ data.username }}">{{ data.username }}</td>
                  <td>{{data.nameRole}}
                    <!--                  <ng-container *ngFor="let role of data.usersRoles">-->
                    <!--                    {{role.roleName}}-->
                    <!--                  </ng-container>-->
                  </td>
                  <td>{{data.phoneNumber}}</td>
                  <td nzEllipsis nz-tooltip nzTooltipTitle="{{ data.email }}">
                    {{data.email}}
                  </td>
                  <td>{{data.createdAt}}</td>
                  <td nzAlign="center">
                    <!-- <div class="custom-control custom-switch">-->
                    <!--                    <input type="checkbox" class="custom-control-input" id="customSwitch1">-->
                    <!--                    <label class="custom-control-label" for="customSwitch1"></label>-->
                    <!--                  </div> -->
                    <nz-switch nzSize="small" [ngModel]="data.status" [nzControl]="true"
                      (click)="showPopupChangeStatus(data)" [nzDisabled]="userInfo.userType == 'ADV'">
                    </nz-switch>
                  </td>
                  <td nzAlign="center">
                    <button [disabled]="!roleAttribute.userUpdate || userInfo.userType == 'ADV'" nz-button
                      nzType="primary" [nzSize]="size" (click)="showModalEdit(data)"><i nz-icon nzType="edit"
                        nzTheme="outline"></i></button>
                  </td>
                </tr>
              </tbody>
              <div style="position: absolute" class="mt-3 ml-3">Total: {{total}}</div>
            </nz-table>
            <div style="float: right; margin-top: 12px;">
              <nz-pagination [nzPageIndex]="1" [nzPageSize]="10" [nzTotal]="total" (nzPageIndexChange)="onPageIndexChange($event)"></nz-pagination>
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>
<app-user-update *ngIf="isVisible" [isVisible]="isVisible" [isEdit]="isEdit" [userEdit]="userEdit"
  (changeDataEdit)="changeDataEdit($event)" (resetIsEdit)="resetEdit($event)" (isClose)="isClose($event)">
</app-user-update>

<nz-modal [(nzVisible)]="isVisibleChangeStatus" nzCentered>
  <ng-container>
    <div class="content-popup-user">
      <i nz-icon nzType="question-circle" nzTheme="outline"></i>
      <h4>Are you sure you want to change status?</h4>
    </div>
  </ng-container>
  <div *nzModalFooter class="btn-active-status">
    <button nz-button nzType="primary" (click)="handleConfirm()" [nzLoading]="isConfirmLoading">Yes, change it!</button>
    <button nz-button nzDanger nzType="primary" (click)="handleCancelPopup()">Cancel</button>
  </div>
</nz-modal>
