<div class="login-container">
  <div class="img-bg">
  </div>
  <div class="login-form">
    <form (keyup.enter)="login()" class="login-form-inner">
      <h1 class="mb-2 text-center" style="font-family: Arial ; color: blue">SIGN IN</h1>
      <br>
      <br>
      <br>
      <div class="logo-login mb-3">
        <img class="img-fluid" src="assets/svg/LOGO-MOBIFONE-PNG.png" alt="Không có ảnh"/>
      </div>
      <br>
      <br>
      <br>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="feather icon-user"></i></span>
        </div>
        <input (input)="logValue($event)" (blur)="blurEmailEvent($event)" [type]=show name="username"
               (keyup.enter)="login()"
               [value]="userObj.username" placeholder="Username" class="form-control"/>
      </div>
      <div *ngIf="(emailTouched || emailDirty) && !userObj.username" class="text-danger text-left mt-1 mb-2">
        The Username is required!
      </div>
      <div class="input-group icon-wrapper mb-4">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="feather icon-lock"></i></span>
        </div>
        <input [type]="password" (input)="logValuePass($event)" (blur)="blurPassEvent($event)" name="password"
               placeholder="Password"
               (keyup.enter)=login()
               [value]="userObj.password" class="form-control"/>
          <i (click)="hidePassword()" class="fa {{show ? 'fa-eye' : 'fa-eye-slash'}} icon-inner" aria-hidden="true"></i>
      </div>
      <div *ngIf="(passwordTouched || passwordDirty) && !userObj.password" class="text-danger text-left mt-1 mb-2">
        The Password is required!
      </div>
      <button [disabled]="!isValidForm()" (click)="login()" class="btn btn-primary mx-auto d-block">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
        Login
      </button>
      <div *ngIf="isLoginFail" class="text-danger text-left mt-2">
        Login failed, account or password does not exist! Please try again!
      </div>
    </form>
  </div>
</div>

