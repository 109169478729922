import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import {Observable} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {UserInfo} from '../../model/user-info/user-info.model';
import {tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  urlCreate = '/api/user/user/create/';
  urlUpdate = '/api/user/user/update/';
  urlUpdateStatus = '/api/user/user/update-status/';
  urlGetAll = '/api/user/user/get-all';
  urlSearchUser = '/api/user/user/search-user';
  constructor(private http: HttpClient) {
  }

  createUser(user: UserInfo): Observable<any> {
    const dataUser = new FormData();
    if (user.file) {
      dataUser.append('file', user.file);
    }
    dataUser.append('username', user.username);
    dataUser.append('password', user.password);
    // @ts-ignore
    dataUser.append('gender', user.gender);
    // @ts-ignore
    dataUser.append('roleId', user.roleId);
    // @ts-ignore
    dataUser.append('status', user.status);
    dataUser.append('firstName', user.firstName);
    dataUser.append('lastName', user.lastName);
    dataUser.append('dateOfBirth', user.dateOfBirth);
    dataUser.append('email', user.email);
    dataUser.append('phoneNumber', user.phoneNumber);
    dataUser.append('address', user.address);
    dataUser.append('position', user.position);
    dataUser.append('avatar', user.avatar);
    // @ts-ignore
    dataUser.append('parentUserId', user.parentUserId);
    dataUser.append('level', user.level);
    dataUser.append('userType', user.userType);
    dataUser.append('createdAt', user.createAt);
    console.log(dataUser, 'phucmn');
    return this.http.post(`${environment.apiUrl}${this.urlCreate}`, dataUser).pipe(
      tap(data => {
        console.log(data, ' dataaaaaaaaaaaa');
      })
    );
  }
  updateUser(user: UserInfo): Observable<any> {
    const dataUser = new FormData();
    if (user.file) {
      dataUser.append('file', user.file);
    }
    // @ts-ignore
    dataUser.append('userId', user.userId);
    dataUser.append('username', user.username);
    dataUser.append('password', user.password);
    // @ts-ignore
    dataUser.append('gender', user.gender);
    // @ts-ignore
    dataUser.append('roleId', user.roleId);
    // @ts-ignore
    dataUser.append('status', user.status);
    dataUser.append('firstName', user.firstName);
    dataUser.append('lastName', user.lastName);
    // @ts-ignore
    dataUser.append('dateOfBirth', new Date(user.dateOfBirth));
    dataUser.append('email', user.email);
    dataUser.append('phoneNumber', user.phoneNumber);
    dataUser.append('address', user.address);
    dataUser.append('position', user.position);
    dataUser.append('avatar', user.avatar);
    if (user.parentUserId !== null) {
      // @ts-ignore
      dataUser.append('parentUserId', user.parentUserId);
    }
    dataUser.append('level', user.level);
    dataUser.append('userType', user.userType);
    dataUser.append('createdAt', user.createAt);
    return this.http.post(`${environment.apiUrl}${this.urlUpdate}`, dataUser).pipe(
      tap(data => {
        console.log(data, ' update');
      })
    );
  }

  getAllUsers() {
    return this.http.get(`${environment.apiUrl}${this.urlGetAll}`).pipe(
      tap(data => {
        console.log(data, ' dataaaaaaaaaaaa');
      })
    );
  }

  searchUser(user: UserInfo): Observable<any> {
    return this.http.post(`${environment.apiUrl}${this.urlSearchUser}`, user).pipe(
      tap(data => {
        console.log(data, 'searchUser');
      })
    );
  }
  updateStatus(user) {
    return this.http.post(`${environment.apiUrl}${this.urlUpdateStatus}`, user).pipe(
      tap(data => {
        console.log(data, 'status update');
      })
    );
  }

}
