<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzWidth]="700" [nzOkText]="buttonText" [nzTitle]="title" (nzOnCancel)="handleCancel()"
          (nzOnOk)="handleOk()">
  <ng-container>
    <form [formGroup]="createUserForm">
      <div class="row">
        <div class="col md-6">
          <div class="mt-3">
            <label for="username">Username</label><span style="color: red">&nbsp;*</span>
            <input autocomplete="new-username" (keydown.space)="$event.preventDefault()" formControlName="username" type="text" nz-input id="username" maxlength="50">
            <ng-container *ngFor="let mess of validCreateUserForm.username">
              <div class="error-mess" *ngIf="createUserForm.get('username').hasError(mess.type)  && (createUserForm.get('username').touched || createUserForm.get('username').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col md-6">
          <div class="mt-3">
            <label for="username">Create user for</label><span style="color: red">&nbsp;*</span>
            <nz-select  id="userType" formControlName="userType" class="w-100" nzPlaceHolder="Select user type">
              <nz-option nzValue="ADMIN" nzLabel="ADMIN" *ngIf="userInfo.userType != 'ADMIN'"></nz-option>
              <nz-option nzValue="ADV" nzLabel="ADV"></nz-option>
              <nz-option nzValue="PUBLISHER" nzLabel="PUBLISHER" *ngIf="isEdit"></nz-option>
            </nz-select>
            <ng-container *ngFor="let mess of validCreateUserForm.userType">
              <div class="error-mess" *ngIf="createUserForm.get('userType').hasError(mess.type) && (createUserForm.get('userType').touched || createUserForm.get('userType').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row" [ngClass]="isEdit ? 'd-none' : ''">
        <div class="col md-6">
          <div class="mt-3">
            <label for="password">Password</label><span style="color: red">&nbsp;*</span>
            <input autocomplete="new-password" (keydown.space)="$event.preventDefault()" formControlName="password" type="password" nz-input id="password" maxlength="12">
            <ng-container *ngFor="let mess of validCreateUserForm.password">
              <div class="error-mess" *ngIf="createUserForm.get('password').hasError(mess.type) && (createUserForm.get('password').touched || createUserForm.get('password').dirty|| isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col md-6">
          <div class="mt-3">
            <label for="confirmPassword">Confirm password</label><span style="color: red">&nbsp;*</span>
            <input (keydown.space)="$event.preventDefault()" formControlName="confirmPassword" type="password" nz-input id="confirmPassword">
            <ng-container *ngFor="let mess of validCreateUserForm.confirmPassword">
              <div class="error-mess" *ngIf="createUserForm.get('confirmPassword').hasError(mess.type) && (createUserForm.get('confirmPassword').touched || createUserForm.get('confirmPassword').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col md-6">
          <div class="mt-3">
            <label for="firstName">First name</label><span style="color: red">&nbsp;*</span>
            <input (blur)="preventSpace()" formControlName="firstName" type="text" nz-input id="firstName" maxlength="50">
            <ng-container *ngFor="let mess of validCreateUserForm.firstName">
              <div class="error-mess" *ngIf="createUserForm.get('firstName').hasError(mess.type) && (createUserForm.get('firstName').touched || createUserForm.get('firstName').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col md-6">
          <div class="mt-3">
            <label for="lastName">Last name</label><span style="color: red">&nbsp;*</span>
            <input (blur)="preventSpace()" formControlName="lastName" type="text" nz-input id="lastName" maxlength="50">
            <ng-container *ngFor="let mess of validCreateUserForm.lastName">
              <div class="error-mess" *ngIf="createUserForm.get('lastName').hasError(mess.type) && (createUserForm.get('lastName').touched || createUserForm.get('lastName').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col md-6">
          <div class="mt-3">
            <label for="gender">Gender</label><span style="color: red">&nbsp;*</span>
            <nz-select id="gender" class="w-100" formControlName="gender"  >
              <nz-option nzValue="1" nzLabel="Male" ngDefaultControl></nz-option>
              <nz-option nzValue="2" nzLabel="Female"></nz-option>
            </nz-select>
            <ng-container *ngFor="let mess of validCreateUserForm.gender">
              <div class="error-mess" *ngIf="createUserForm.get('gender').hasError(mess.type) && (createUserForm.get('gender').touched || createUserForm.get('gender').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col md-6">
          <div class="mt-3">
            <label for="dateOfBirth">Birthday</label><span style="color: red">&nbsp;*</span>
            <nz-date-picker
                            formControlName="dateOfBirth"
                            class="w-100"
                            id="dateOfBirth"
                            nzPlaceHolder="dd/mm/yyyy"
                            [nzFormat]="formatDate"
                            [nzDisabledDate]="disabledDate"
                            [nzInputReadOnly]="true"
            ></nz-date-picker>
            <ng-container *ngFor="let mess of validCreateUserForm.dateOfBirth">
<!--              <div class="error-mess" *ngIf="createUserForm.get('dateOfBirth').hasError(mess.type) && (isDatePickTouched || isSubmit)">-->
<!--                {{mess.message}}-->
<!--              </div>-->
              <div class="error-mess" *ngIf="createUserForm.get('dateOfBirth').hasError(mess.type) && (createUserForm.get('dateOfBirth').touched || createUserForm.get('dateOfBirth').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col md-6">
          <div class="mt-3">
            <label for="email">Email</label><span style="color: red">&nbsp;*</span>
            <input formControlName="email" type="email" nz-input id="email" maxlength="50">
            <ng-container *ngFor="let mess of validCreateUserForm.email">
              <div class="error-mess" *ngIf="createUserForm.get('email').hasError(mess.type) && (createUserForm.get('email').touched || createUserForm.get('email').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col md-6">
          <div class="mt-3">
            <label for="phoneNumber">Phone number</label><span style="color: red">&nbsp;*</span>
            <input (keydown.space)="$event.preventDefault()" formControlName="phoneNumber" type="text" nz-input id="phoneNumber" maxlength="11">
            <ng-container *ngFor="let mess of validCreateUserForm.phoneNumber">
              <div class="error-mess" *ngIf="createUserForm.get('phoneNumber').hasError(mess.type) && (createUserForm.get('phoneNumber').touched || createUserForm.get('phoneNumber').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col md-12">
          <div class="mt-3">
            <label for="address">Address</label>
            <input (blur)="preventSpace()" formControlName="address" type="text" nz-input id="address" maxlength="200">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col md-12">
          <div class="mt-3">
            <label for="position">Position</label>
            <input (blur)="preventSpace()" formControlName="position" type="text" nz-input id="position" maxlength="100">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col md-12">
          <div class="mt-3">
            <label for="roleId">Role</label><span style="color: red">&nbsp;*</span>
            <nz-select [nzDisabled]="isEdit && userEdit.userType ==='PUBLISHER'" formControlName="roleId" class="w-100" nzPlaceHolder="Select Role" nzMode="multiple" nzAllowClear>
              <nz-option *ngFor="let role of (userRoleSub$ | async)"  [nzValue]="role['roleId']" [nzLabel]="role['roleName']"></nz-option>
            </nz-select>
            <ng-container *ngFor="let mess of validCreateUserForm.roleId">
              <div class="error-mess" *ngIf="createUserForm.get('roleId').hasError(mess.type) && (createUserForm.get('roleId').touched || createUserForm.get('roleId').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col md-6">
          <div class="mt-3">
            <label for="level">Level</label><span style="color: red">&nbsp;*</span>
            <nz-select id="level" class="w-100" formControlName="level" >
              <nz-option nzValue="1" nzLabel="LEVEL_1"></nz-option>
              <nz-option nzValue="2" nzLabel="LEVEL_2"></nz-option>
              <nz-option nzValue="3" nzLabel="LEVEL_3"></nz-option>
              <nz-option nzValue="4" nzLabel="LEVEL_4"></nz-option>
              <nz-option nzValue="5" nzLabel="LEVEL_5"></nz-option>
              <nz-option nzValue="6" nzLabel="LEVEL_6"></nz-option>
              <nz-option nzValue="7" nzLabel="LEVEL_7"></nz-option>
              <nz-option nzValue="8" nzLabel="LEVEL_8"></nz-option>
              <nz-option nzValue="9" nzLabel="LEVEL_9"></nz-option>
              <nz-option nzValue="10" nzLabel="LEVEL_10"></nz-option>
            </nz-select>
            <ng-container *ngFor="let mess of validCreateUserForm.level">
              <div class="error-mess" *ngIf="createUserForm.get('level').hasError(mess.type) && (createUserForm.get('level').touched || createUserForm.get('level').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col md-6">
          <div class="mt-3">
            <label for="status">Status</label><span style="color: red">&nbsp;*</span>
            <nz-select [nzDisabled]="isEdit" id="status" class="w-100" formControlName="status"  >
              <nz-option nzValue="1" nzLabel="Active"></nz-option>
              <nz-option nzValue="0" nzLabel="Inactive" [nzDisabled]= "!isEdit"></nz-option>
            </nz-select>
            <ng-container *ngFor="let mess of validCreateUserForm.status">
              <div class="error-mess" *ngIf="createUserForm.get('status').hasError(mess.type) && (createUserForm.get('status').touched || createUserForm.get('status').dirty || isSubmit)">
                {{mess.message}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isEdit">
        <div class="col md-12">
          <div class="mt-3">
            <label for="createdAt">Created at</label>
            <input formControlName="createdAt" type="text" nz-input id="createdAt">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4" *ngIf="isEdit">
          <div class="mt-3">
            <label>Curent avatar</label>
            <div><img class="avatar" [src]="userEdit.avatar || 'assets/images/avatar-default.jpg'"></div>
          </div>
        </div>
        <div [ngClass]="isEdit? 'col-md-8' : 'col-md-12'">
          <div class="mt-3">
            <label for="avatar">Avatar</label>
            <div [ngClass]="isEdit ? 'disabled-control' : '' " (click)="activeFileInput()" class="custom-file-wrapper">
              <span *ngIf="isEdit">{{ userEdit?.avatar}}</span>
              <span *ngIf="!isEdit">{{filePathName}}</span>
              <button [disabled]="isEdit" type="button" nz-button class="mask-fileInput">Browse</button>
              <input type="hidden" formControlName="avatar">
              <input #fileInput
                [disabled]="isEdit"
                (change)="handleFileSelect($event)"
                (input)="getFileName($event)"
                class="hidden-input-file"
                type="file"
                nz-input id="avatar"
                multiple accept=".jpg, .png">
            </div>
          </div>
        </div>
      </div>

    </form>
  </ng-container>
</nz-modal>
