<nz-modal [nzBodyStyle]="{'min-height': '500px'}" [nzWidth]="400" [(nzVisible)]="isVisible" nzFooter="null" (nzOnCancel)="handleCancel()">
    <ng-container>
        <div class="profile">
            <div class="avatar">
                <img [src]="model?.avatar || 'assets/images/user/avatar-1.jpg'" alt="" srcset="">
            </div>
            <div class="info">
                <label>User name: </label><span>{{model?.username}}</span>
            </div>
        </div>
        <hr>
        <div class="info-detail">
            <div class="item">
                <label for="">ID</label>
                <span>{{model?.profiles?.profileId}}</span>
            </div>
            <div class="item">
                <label for="">Full name</label>
                <span>{{model?.profiles?.firstName || 'N/A'}}&nbsp;{{model.profiles.lastName || 'N/A'}}</span>
            </div>
            <div class="item">
                <label for="">Level ID</label>
                <span>{{model?.profiles?.level}}</span>
            </div>
            <div class="item">
                <label for="">Birthday</label>
                <span>{{model?.profiles?.dateOfBirth | date: "dd/MM/yyyy" }}</span>
            </div>
            <div class="item">
                <label for="">Phone number</label>
                <span>{{model?.profiles?.phoneNumber}}</span>
            </div>
            <div class="item">
                <label for="">User type</label>
                <span>{{model?.userType}}</span>
            </div>


        </div>
        <div class="address">
            <label for="">Email</label>
            <span>{{model?.email }}</span>
        </div>
        <div class="address">
            <label for="">Address</label>
            <span>{{model?.profiles?.address || 'N/A'}}</span>
        </div>
    </ng-container>
</nz-modal>
