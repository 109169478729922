import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './theme/shared/shared.module';

import {AppComponent} from './app.component';
import {AdminComponent} from './theme/layout/admin/admin.component';
// import {AuthComponent} from './theme/layout/auth/auth.component';
import {NavigationComponent} from './theme/layout/admin/navigation/navigation.component';
import {NavContentComponent} from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavGroupComponent} from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavCollapseComponent} from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import {NavItemComponent} from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {NavBarComponent} from './theme/layout/admin/nav-bar/nav-bar.component';
import {NavLeftComponent} from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import {NavSearchComponent} from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {NavRightComponent} from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import {ConfigurationComponent} from './theme/layout/admin/configuration/configuration.component';
import {HttpClientModule} from '@angular/common/http';
import {ToggleFullScreenDirective} from './theme/shared/full-screen/toggle-full-screen';
/* Menu Items */
import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import {NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import en from '@angular/common/locales/en';
import {registerLocaleData} from '@angular/common';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {AuthModule} from './auth/auth.module';
import {UserComponent} from './affiliate-client/pages/user-management/user/user.component';
import {UserManagementModule} from './affiliate-client/pages/user-management/user-management.module';
registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    // AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    AuthModule,
    UserManagementModule,
  ],
  providers: [
    NavigationItem,
    {provide: NZ_I18N, useValue: en_US},
    UserComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
