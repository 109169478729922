import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NzModalService, NzNotificationModule, NzNotificationService } from 'ng-zorro-antd';
import { User } from 'src/app/model/user';
import { UserInfo } from 'src/app/model/user-info/user-info.model';
import { RoleService } from 'src/app/services/role/role.service';
import { UserInfoService } from 'src/app/services/user-info/user-info.service';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Observable } from 'rxjs';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnChanges {
  user: User;
  today = new Date();
  submitted = false;
  isVisible = false;
  filePathShow = 'Choose file ...';
  avatar: string | any;
  listRole: [] = [];
  listRoleUser: any[] = [];
  emailExists: boolean;
  isNumber = false;
  userEditForm: FormGroup;
  errorSelectImg = false;
  listStatus = [
    {
      type: 'Active',
      num: '1'
    },
    {
      type: 'Inactive',
      num: '0'
    },
  ];

  constructor(
    private fb: FormBuilder,
    private modal: NzModalService,
    private userInfoService: UserInfoService,
    private roleService: RoleService,
    private userService: UserService,
    private notification: NzNotificationService
  ) {}

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  preventSpace() {
    const filedAddress = this.userEditForm.get('address');
    const filedFirstname = this.userEditForm.get('firstName');
    const filedLastname = this.userEditForm.get('lastName');
    const filedPosition = this.userEditForm.get('position');
    if (filedAddress.value) {
      const value = filedAddress.value.toString().trim();
      filedAddress.setValue(value);
    }
    if (filedFirstname.value) {
      const value = filedFirstname.value.toString().trim();
      filedFirstname.setValue(value);
    }
    if (filedLastname.value) {
      const value = filedLastname.value.toString().trim();
      filedLastname.setValue(value);
    }
    if (filedPosition.value) {
      const value = filedPosition.value.toString().trim();
      filedPosition.setValue(value);
    }
  }

  focusOut() {
    this.userEditForm.patchValue({
      firstName: this.userEditForm.controls.firstName.value ?
                 this.userEditForm.controls.firstName.value.trim() : this.userEditForm.controls.firstName.value,
      lastName: this.userEditForm.controls.lastName.value ?
                this.userEditForm.controls.lastName.value.trim() : this.userEditForm.controls.lastName.value,
      email: this.userEditForm.controls.email.value ?
             this.userEditForm.controls.email.value.trim() : this.userEditForm.controls.email.value,
      phoneNumber: this.userEditForm.controls.phoneNumber.value ?
                   this.userEditForm.controls.phoneNumber.value.trim() : this.userEditForm.controls.phoneNumber.value,
      address: this.userEditForm.controls.address.value ?
               this.userEditForm.controls.address.value.trim() : this.userEditForm.controls.address.value,
      position: this.userEditForm.controls.position.value ?
                this.userEditForm.controls.position.value.trim() : this.userEditForm.controls.position.value,
    });
  }

  blockSpace() {
    this.userEditForm.patchValue({
      firstName: this.userEditForm.controls.firstName.value.replace(/^\s+/g, ''),
      lastName: this.userEditForm.controls.lastName.value.replace(/^\s+/g, ''),
      email: this.userEditForm.controls.email.value.replace(/^\s+/g, ''),
      phoneNumber: this.userEditForm.controls.phoneNumber.value.replace(/^\s+/g, ''),
      address: this.userEditForm.controls.address.value.replace(/^\s+/g, ''),
      position: this.userEditForm.controls.position.value.replace(/^\s+/g, ''),
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {

    this.userEditForm = this.fb.group({
      address: [, ],
      avatar: [],
      confirmPassword: [],
      createdAt: [],
      dateOfBirth: [, [Validators.required, ]],
      email: [, {
        validators: [Validators.required, Validators.pattern(
          '[a-z0-9\\.[a-z0-9]*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?')],
        asyncValidators: this.emailValidator()
      }],
      firstName: [, [Validators.required, this.noWhitespaceValidator]],
      gender: [, [Validators.required, ]],
      lastName: [, [Validators.required, this.noWhitespaceValidator]],
      level: [],
      parentUserId: [],
      password: [],
      phoneNumber: ['', [Validators.required,
      Validators.pattern('(03|07|08|84|09|01[2|6|8|9]|05[6|8|9])+([0-9]{8,9})')],
      ],
      position: [],
      roleId: [[], ],
      status: [],
      userId: [],
      userType: [],
      username: [],
      file: []
    });

    this.userEditForm.patchValue({
      ...this.user,
      address: this.user.profiles.address || '',
      dateOfBirth: this.user.profiles.dateOfBirth,
      email: this.user.profiles.email,
      firstName: this.user.profiles.firstName,
      gender: this.user.profiles.gender,
      lastName: this.user.profiles.lastName,
      level: this.user.profiles.level.toString() || '1',
      phoneNumber: this.user.profiles.phoneNumber,
      position: this.user.profiles.position || '',
      userId: this.user.userId,
      status: this.user.status.toString()
    });
    this.user.usersRoles.forEach((res: any) => {
      this.userEditForm.value.roleId.push(res.roleId);
    });
    this.avatar = this.user.avatar;
    this.getAllROle();
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0

  get f() {
    return this.userEditForm.controls;
  }



  genderShow() {
    if (this.user.profiles.gender == 1) {
      return 'Male';
    }
    if (this.user.profiles.gender == 2) {
      return 'Female';
    }
  }

  checkValidPhone(event) {
    this.userEditForm.patchValue({
      phoneNumber: this.userEditForm.controls.phoneNumber.value.trim(),
    });
    if (isNaN(event)) {
      this.isNumber = true;

    } else {
      this.isNumber = false;

    }
  }

  statusShow() {
    if (this.user.status == 1) {
      return 'Active';
    }
    if (this.user.status == 0) {
      return 'Inactive';
    }
  }

  editUser() {
    this.submitted = true;
    this.userEditForm.value.avatar = this.avatar;
    if (this.userEditForm.invalid || this.emailExists || this.errorSelectImg) {
    } else {
      this.userInfoService.updateUser(this.userEditForm.value).subscribe((res: any) => {
      },
        (err) => {
          this.notification.error('Your user has been edit failed', '');
        },
        () => {
          this.notification.success('Your user has been update', '');
          setTimeout(() => this.modal.closeAll(), 1500);
        });

    }
  }


  onChange($event: any) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileData = file;
    this.userEditForm.patchValue({
      file: fileData
    });
    if (file.type.split('/')[0] !== 'image') {
      this.errorSelectImg = true;
    } else {
      this.errorSelectImg = false;
      const pathFile: string = $event.target.value;
      this.filePathShow = pathFile.length > 43 ? (pathFile.slice(0, 43) + '...') : pathFile;
      const filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = (res: any) => {
        this.avatar = res.currentTarget.result;
      };
    }

  }

  closeModal() {
    this.modal.closeAll();
  }

  getAllROle() {
    this.roleService.getAllRole().subscribe(res => {
      this.listRole = res.responseData;
      this.listRole.forEach((ele: any) => {
        this.userEditForm.value.roleId.forEach((data) => {
          if (data == ele.roleId) {
            this.listRoleUser.push(ele);
          }
        });
      });
    });
  }

  emailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<any> => {
      return this.userService.checkIfemailExists(control.value.toLowerCase()).pipe(
        map(res => {
          const emailUser: any = this.user.email;
          if (res.responseData == true) {
            if (control.value.toLowerCase() == emailUser) {
              this.emailExists = false;
            } else {
              this.emailExists = true;
            }
          } else {
            this.emailExists = res.responseData;
          }
        })
      );
    };
  }
}
