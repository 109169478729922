// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://10.10.151.188:9989'
  //apiUrl: 'http://localhost:9989',
  // apiUrlADV: 'http://192.168.91.52:9989',
  // apiUrl: 'http://137.59.47.127:9989',
   apiUrl: 'https://aff.gemdigital.vn:9988'


};

// apiUrl: 'http://14.225.5.246:9989'
// apiUrl: 'http://localhost:9989'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';
// Included with Angular CLI.
