import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {of, Observable, Subject} from 'rxjs';
import {catchError, mapTo, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Tokens} from '../../model/tokens';
import {User} from '../../model/user';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly USER_INFO = 'USER_INFO';
  private loggedUser: string;
  private url = '/api/user/auth/login/';

  constructor(private http: HttpClient, private router: Router) {
  }

  check(user: User): Observable<any> {
    return this.http.post(`${environment.apiUrl}${this.url}`, user);
  }

  login(user: User): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}${this.url}`, user)
      .pipe(
        tap(tokens => {
          const token: Tokens = {
            jwt: tokens.responseData.accessToken,
             refreshToken: tokens.responseData.refreshToken,
          };
          this.doLoginUser(user.username, token);
          this.getUserInfo(tokens.responseData.user);
        }),
        mapTo(true),
        catchError(error => {
          return of(false);
        }));
  }

  logout() {
    
    return this.http.post<any>(`${environment.apiUrl}/logout`, {
      refreshToken: this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        alert(error.error);
        return of(false);
      }));
  }

  logOutDemo() {
    this.removeTokens();
    this.router.navigate(['login']);
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http.post<any>(`${environment.apiUrl}/api/user/auth/refresh_token/`, {
      "grant_type": "refresh_token",
      "refreshToken": this.getRefreshToken()
    }).pipe(tap((tokens: any) => {
      if(tokens.responseData !== null){
        localStorage.setItem("JWT_TOKEN", tokens.responseData.accessToken);
        localStorage.setItem("REFRESH_TOKEN", tokens.responseData.refreshToken);
      }
      else{
        Swal.fire({
          position: 'center',
          text: "Phiên đăng nhập đã kết thúc!",
          icon: 'warning',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
           this.logOutDemo();
        }, 1500);
      }
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }


  private doLoginUser(username: string, tokens: Tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private getUserInfo(user: User) {
    localStorage.setItem(this.USER_INFO, JSON.stringify(user));
  }

  private doLogoutUser() {
    // this.router.navigate(['/login']);
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.clear();
  }
}
