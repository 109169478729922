import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppParamService } from 'src/app/services/app-param/app-param.service';
import { GradientConfig } from '../../../../app-config';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public gradientConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  constructor(
    private appParamService: AppParamService
  ) {
    this.gradientConfig = GradientConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
  }
  logoImg: string;

  ngOnInit() {
    this.getUrlImage();
  }

  getUrlImage() {
    this.appParamService.getImageByCode("IMGLOGO").subscribe(res => {
      this.logoImg = res.responseData[0].paramValue;
    }, (err) => { }, () => { });
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

}
