import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user/user.component';
import { RolesComponent } from './roles/roles.component';
import { UserDecentralizationManagementComponent } from './user-decentralization-management/user-decentralization-management.component';
import { RouterModule, Routes } from '@angular/router';
import { CardModule } from '../../../theme/shared/components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { UserUpdateComponent } from './user/user-update/user-update.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import {NumbericDirective} from '../../directive/numberic.directive';
import { MyProfileComponent } from './my-profile/my-profile.component';
import {NzPaginationModule, NzSpinModule, NzToolTipModule} from 'ng-zorro-antd';
import { UserInfoComponent } from './user-info/user-info.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
const routers: Routes = [
  {
    path: 'user',
    component: UserComponent
  },
  {
    path: 'role',
    component: RolesComponent
  },
  {
    path: 'decentralization',
    component: UserDecentralizationManagementComponent
  }

];


@NgModule({
  declarations: [MyProfileComponent, UserComponent, RolesComponent, UserDecentralizationManagementComponent, UserUpdateComponent, UserUpdateComponent, NumbericDirective, UserInfoComponent],
  exports: [
    UserUpdateComponent,
    MyProfileComponent,
    NumbericDirective,
    UserInfoComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routers),
        CardModule,
        NgbModule,
        NzDatePickerModule,
        FormsModule,
        NzTableModule,
        NzButtonModule,
        NzInputModule,
        NzIconModule,
        NzModalModule,
        NzSelectModule,
        ReactiveFormsModule,
        NzDropDownModule,
        NzUploadModule,
        NzSwitchModule,
        NzToolTipModule,
        NzSpinModule,
        NzFormModule,
        NzNotificationModule,
        NzPaginationModule
    ],
  entryComponents: [UserInfoComponent]
})
export class UserManagementModule {
}
