import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { UserInfoService } from '../../../../../services/user-info/user-info.service';
import { RoleService } from '../../../../../services/role/role.service';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { UserInfo } from '../../../../../model/user-info/user-info.model';
import { CustomValidator } from '../../../../../theme/shared/common/CustomValidator';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../../../../services/user.service';
import { Constants } from '../../../../../theme/shared/common/constants';
import { User } from '../../../../../model/user';
import { UserComponent } from '.././user.component';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { AdminService } from '../../../../../services/admin/admin.service';
import { environment } from '../../../../../../environments/environment';
import { ToastService } from 'src/app/theme/shared/components/toast/toast.service';
import { NzNotificationService } from 'ng-zorro-antd';
@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent implements OnInit, OnChanges {
  today = new Date();
  formatDate = 'dd/MM/yyyy';
  title: string;
  buttonText: string;
  isSubmit: boolean;
  filePathName: string;
  userInfo: UserInfo;
  imageToShow: any = null;
  isImageLoading = false;
  userId: number;
  userInfoList: UserInfo[] = [];
  userRoleSub = new Subject<any[]>();
  userRoleSub$ = this.userRoleSub.asObservable();

  constructor(private fb: FormBuilder,
    private i18n: NzI18nService,
    private userInfoService: UserInfoService,
    private userService: UserService,
    private roleService: RoleService,
    private adminService: AdminService,
    private notification: NzNotificationService,
    private userComponent: UserComponent) {

  }

  @Input() isVisible: boolean;
  @Input() isEdit: boolean;
  @Output() resetIsEdit = new EventEmitter<any>();
  @Output() changeDataEdit = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() isClose = new EventEmitter<any>();
  @Input() userEdit: User;
  isDatePickTouched: boolean;
  allRole: any[] = [];
  createUserForm: FormGroup;
  size: NzButtonSize = 'default';
  date = null;
  validCreateUserForm = {
    username: [
      { type: 'customRequired', message: 'Username is required' },
      { type: 'minLengthCustom', message: 'The username must be at least 6 characters' },
      { type: 'pattern', message: 'The username must not have uppercase letters, special characters' },
      { type: 'usernameExists', message: 'The username already exists' },
    ],
    password: [
      { type: 'required', message: 'Password is required' },
      { type: 'minLengthCustom', message: 'The password must be at least 8 characters' },
    ],
    gender: [
      { type: 'required', message: 'Gender is required' },
    ],
    roleId: [
      { type: 'required', message: 'Role is required' },
    ],
    status: [
      { type: 'required', message: 'Status is required' },
    ],
    firstName: [
      { type: 'required', message: 'Firstname is required' },
    ],
    lastName: [
      { type: 'required', message: 'Lastname is required' },
    ],
    dateOfBirth: [
      { type: 'required', message: 'Birthday is required' },
    ],
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'The email format is invalid' },
      { type: 'emailExists', message: 'The email already exists' },
    ],
    phoneNumber: [
      { type: 'required', message: 'Phone number is required' },
      { type: 'minLengthCustom', message: 'The phone number must be at least 9 characters' },
      { type: 'phoneNumberValidator', message: 'The phone number format is invalid' },
    ],
    level: [
      { type: 'required', message: 'Level is required' },
    ],
    userType: [
      { type: 'required', message: 'Create user for is required' },
    ],
    confirmPassword: [
      { type: 'required', message: 'Confirm Password is required' },
      { type: 'errorMatchingPass', message: 'Confirm Password is not match' },
    ],
  };


  ngOnInit(): void {
    this.getUserId();
  }

  createForm() {
    this.createUserForm = this.fb.group({
      username: ['', {
        validators: [CustomValidator.validateNoFullSpace, Validators.pattern('^[a-za-z0-9]+$'), CustomValidator.usernameMinlength],
        asyncValidators: this.usernameValidator()
      }],
      password: ['', { validators: [Validators.required, CustomValidator.passwordMinlength] }],
      gender: ['1', Validators.required],
      roleId: [[], Validators.required],
      status: ['1', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      email: ['', {
        validators: [Validators.required, Validators.pattern(
          // tslint:disable-next-line:max-line-length
          '[a-zA-Z0-9\\.[a-zA-Z0-9]*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?')],
        asyncValidators: this.emailValidator()
      }],
      phoneNumber: ['', { validators: [Validators.required, CustomValidator.phoneNumberValidator, CustomValidator.phoneNumberminLength] }],
      address: [''],
      position: [''],
      avatar: [''],
      parentUserId: [],
      level: ['1', Validators.required],
      userType: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      createdAt: ['', { disable: true }],
      file: []
    }, {
      validators: this.checkMatchPassword('password', 'confirmPassword')
    });
  }

  onChange(result: Date): void {
    this.createUserForm.reset();
    this.isDatePickTouched = false;
  }

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;

  usernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.userService.checkIfUsernameExists(control.value).pipe(
        map(res => {
          // if res is true, username exists, return true
          return res.responseData ? { usernameExists: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  emailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.userService.checkIfemailExists(control.value.toLowerCase()).pipe(
        map(res => {
          // if res is true, username exists, return true
          return res.responseData ? { emailExists: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  // Insert or Update user
  handleOk(): void {
    this.isSubmit = true;
    if (this.isEdit) {
      this.createUserForm.get('password').clearValidators();
      this.createUserForm.get('confirmPassword').clearValidators();
      this.createUserForm.get('confirmPassword').setErrors(null);
      this.createUserForm.setErrors(null);
    }
    if (!this.createUserForm.valid) {
      return;
    }
    const user: UserInfo = { ...this.createUserForm.getRawValue() };
    if (this.isEdit) {
      user.userId = this.userEdit.userId;
      user.avatar = this.userEdit.avatar;
      this.userInfoService.updateUser(user).subscribe(next => {
        if (next.responseCode === Constants.RESPONSE_STATUS_SUCCESS) {
          this.resetStatus();
          this.changeDataEdit.emit(next.responseData);
          
          this.notification.success('Your user has been updated', '');
          // alert('Your user has been updated');
        } else {
          this.notification.error('Your user has been update failed', '');
          // alert('Your user has been update failed');
        }
      });
    } else if (this.isEdit === undefined) {

      this.userInfoService.createUser(user).subscribe(next => {
        if (next.responseCode === Constants.RESPONSE_STATUS_SUCCESS) {
          this.userComponent.userInfoListOrigin = [];
          this.resetStatus();
          this.notification.success('Your user has been created', '');
          // alert('Your user has been created');
        } else {
          this.notification.error('Your user has been create failed', '');
          // alert('Your user has been create failed');
        }
      });
      // Reload data grid user
      this.userComponent.userInfoListOrigin = [];
      setTimeout(() => {
        this.userComponent.onSearch();
      }, 1000);
    }
  }

  resetStatus() {
    this.isVisible = false;
    this.isSubmit = false;
    this.createUserForm.reset();
    this.isDatePickTouched = false;
    this.isEdit = undefined;
    this.resetIsEdit.emit(this.isEdit);
  }

  handleCancel(): void {
    this.resetStatus();
    this.isClose.emit(false);
  }

  show() {}

  // password


  checkMatchPassword(formControlPass: string, formControlConfirmPass: string): ValidatorFn {
    return (formGroup: FormGroup) => {
      const pass = formGroup.get(formControlPass).value;
      const confirmPass = formGroup.get(formControlConfirmPass).value;
      if (pass !== confirmPass) {
        formGroup.get(formControlConfirmPass).setErrors({ errorMatchingPass: true });
        return { errorPass: true };
      } else {
        formGroup.get(formControlConfirmPass).setErrors(null);
      }
      return null;
    };
  }

  getAllRoleToLoadCombobox() {
    this.roleService.getAllRole().subscribe(next => {
      if (next.responseCode === Constants.RESPONSE_STATUS_SUCCESS) {
        this.allRole = next.responseData;
        this.userRoleSub.next(next.responseData);
      } else {
        this.allRole = [];
      }
    });
  }

  openBlur() {}

  activeFileInput() {
    this.fileInput.nativeElement.click();
  }

  getFileName($event) {
    this.filePathName = $event.target.value;
  }

  getUserId() {
    const userInfoTemp = localStorage.getItem('USER_INFO');
    this.userInfo = JSON.parse(userInfoTemp);
    this.userId = this.userInfo.userId;
    this.createUserForm.get('parentUserId').setValue(this.userId);
  }

  handleFileSelect($event) {
    const files = $event.target.files;
    const fileData = files[0];
    this.createUserForm.patchValue({
      file: fileData
    });
    const validImg = ['jpeg', 'jpg', 'png'];
    const imgExt = this.filePathName.substring(this.filePathName.lastIndexOf('.') + 1);
    const result = validImg.includes(imgExt);
    if (result === false) {
      alert('The image format is invalid');
      return this.filePathName = '';
    } else {
      if (files && fileData) {
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
          this.createUserForm.patchValue({
            avatar: reader.result
          });
        };
      }
    }
  }
  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createUserForm.patchValue({
        avatar: file
      });
    }
  }
  preventSpace() {
    const filedAddress = this.createUserForm.get('address');
    const filedFirstname = this.createUserForm.get('firstName');
    const filedLastname = this.createUserForm.get('lastName');
    const filedPosition = this.createUserForm.get('position');
    if (filedAddress.value) {
      const value = filedAddress.value.toString().trim();
      filedAddress.setValue(value);
    }
    if (filedFirstname.value) {
      const value = filedFirstname.value.toString().trim();
      filedFirstname.setValue(value);
    }
    if (filedLastname.value) {
      const value = filedLastname.value.toString().trim();
      filedLastname.setValue(value);
    }
    if (filedPosition.value) {
      const value = filedPosition.value.toString().trim();
      filedPosition.setValue(value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAllRoleToLoadCombobox();
    this.createForm();
    if (this.isEdit === true) {
      this.title = 'Edit User';
      this.buttonText = 'Edit';
    } else {
      this.title = 'Create New User';
      this.buttonText = 'Create';
    }
    if (this.isEdit && this.userEdit) {
      this.createUserForm.disable();
      this.createUserForm.get('roleId').enable();
      this.createUserForm.get('level').enable();
      this.createUserForm.get('status').enable();
      this.createUserForm.patchValue({
        username: this.userEdit.username,
        gender: this.userEdit.profiles.gender.toString(),
        status: this.userEdit.status.toString(),
        firstName: this.userEdit.profiles.firstName,
        lastName: this.userEdit.profiles.lastName,
        dateOfBirth: this.userEdit.profiles.dateOfBirth,
        email: this.userEdit.email,
        phoneNumber: this.userEdit.profiles.phoneNumber,
        address: this.userEdit.profiles.address,
        position: this.userEdit.profiles.position,
        avatar: this.userEdit.avatar,
        parentUserId: this.userEdit.parentUserId,
        level: this.userEdit.profiles.level?.toString(),
        userType: this.userEdit.userType,
        createdAt: this.userEdit.createdAt,
        password: this.userEdit.password,
      });
      this.userRoleSub$.subscribe(next => {
        const roles: number[] = [];
        if (next && next.length > 0) {
          this.userEdit.usersRoles.forEach((item: any) => {
            roles.push(item.roleId);
            this.createUserForm.get('roleId').setValue(roles);
          });
        }
      });

    }

  }
  wait(timeout) {
    return new Promise(resolve => {
      setTimeout(resolve, timeout);
    });
  }
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getImageFromService() {
    this.isImageLoading = true;
    this.adminService.fetchProfileImage(this.createUserForm.get('avatar').value).subscribe(data => {
      this.createImageFromBlob(data);
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
    });
  }
}
