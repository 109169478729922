import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AppParamModel} from '../../model/app-param/app-param.model';

@Injectable({
  providedIn: 'root'
})
export class AppParamService {
  mainUrl = '/api/admin/param';
  urlGetCode = '/get-all-code/';
  urlGetValue = '/get-by-code/';
  urlSearch = '/search-param/';
  urlModify = '/add-param/';
  urlGetImage = '/get-img-by-code/';
  urlParamValueExist = '/check-exist-param-value/';
  urlParamNameExist = '/check-exist-param-name/';
  urlCompany = '/getComany';
  urlGetParamValue = '/getParamValue';
  urlGetParamValueAndParamName = '/getParamNameValue';
  constructor(private http: HttpClient) {
  }

  getAllParamCode(): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlGetCode}`).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }

  // tslint:disable-next-line:ban-types
  getValueByCode(paramCode: string): Observable<any> {
    const params = new HttpParams().set('paramCode', paramCode);
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlGetValue}`, {params}).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }

  getImageByCode(paramCode: string): Observable<any> {
    const params = new HttpParams().set('paramCode', paramCode);
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlGetImage}`, {params}).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }

  getDataSearch(appParam: AppParamModel): Observable<AppParamModel> {
    return this.http.post(`${environment.apiUrl}${this.mainUrl}${this.urlSearch}`, appParam).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }

  modifyAppParam(appParam: AppParamModel): Observable<AppParamModel> {
    return this.http.post(`${environment.apiUrl}${this.mainUrl}${this.urlModify}`, appParam).pipe(
      tap(data => {
        console.log(data, 'data');
      })
    );
  }

  checkIfParaNameExists(paramName: string, paramCode: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlParamNameExist}`, {params: {paramName, paramCode}});
  }

  checkIfParamValueExists(paramValue: string, paramCode: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlParamValueExist}`, {params: {paramValue, paramCode}});
  }

  getParamValue(paramName: string, paramCode: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlGetParamValue}`, {params: {paramName, paramCode}});
  }
  getParamValueAndName(paramCode: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlGetParamValueAndParamName}`, {params: {paramCode}});
  }
  // get data company
  getComany(paramCode: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.urlCompany}`, {params: {paramCode}});
  }
}
