<div class="container">
    <form [formGroup]="userEditForm">
        <div>
            Type account: {{user.userType}}
        </div>
        <div class="pdTop">
            <div>
                <p>User name<span class="require">&nbsp;*</span></p>
                <input  nz-input value="{{user.username}}" [disabled]="true" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="pdTop">
                    <p>First name<span class="require">&nbsp;*</span></p>
                    <input (input)="blockSpace()" (focusout)="focusOut()" nz-input formControlName="firstName"
                        maxlength="50" />
                    <div *ngIf="f.firstName.errors">
                        <div class="require" *ngIf="f.firstName.errors.required || f.firstName.hasError('whitespace')">
                            Username is required</div>
                    </div>
                </div>
                <div class="pdTop">
                    <p>Gender<span class="require">&nbsp;*</span></p>
                    <nz-select [nzPlaceHolder]="genderShow()" style="width: 100%;" formControlName="gender">
                        <nz-option nzLabel="Male" nzValue="1"></nz-option>
                        <nz-option nzLabel="Female" nzValue="2"></nz-option>
                    </nz-select>
                    <div *ngIf="f.gender.errors">
                        <div class="require" *ngIf="f.gender.errors.required">Gender is required</div>
                    </div>
                </div>
                <div class="pdTop">
                    <p>Email<span class="require">&nbsp;*</span></p>
                    <input (input)="blockSpace()" (focusout)="focusOut()" maxlength="100" nz-input formControlName="email" />
                    <div *ngIf="f.email.errors">
                        <div class="require" *ngIf="f.email.errors.required || f.email.hasError('whitespace')">Email is
                            required</div>
                        <div class="require" *ngIf="f.email.errors.pattern">The email format is invalid</div>
                    </div>
                    <div class="require" *ngIf="emailExists && !f.email.errors">
                        The email already exists
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="pdTop">
                    <p>Last name<span class="require">&nbsp;*</span></p>
                    <input (input)="blockSpace()" (focusout)="focusOut()"  maxlength="50" nz-input formControlName="lastName" />
                    <div *ngIf="f.lastName.errors">
                        <div class="require" *ngIf="f.lastName.errors.required || f.lastName.hasError('whitespace')">
                            Lastname is required</div>
                    </div>
                </div>
                <div class="pdTop">
                    <p>Birthday<span class="require">&nbsp;*</span></p>
                    <nz-date-picker formControlName="dateOfBirth" class="w-100" id="dateOfBirth"
                        nzPlaceHolder="dd/mm/yyyy" [nzFormat]="'dd/MM/yyyy'" [nzDisabledDate]="disabledDate"
                        [nzInputReadOnly]="true">
                    </nz-date-picker>
                    <div *ngIf="f.dateOfBirth.errors">
                        <div class="require" *ngIf="f.dateOfBirth.errors.required">Birthday is required</div>
                    </div>

                </div>
                <div class="pdTop">
                    <p>Phone number<span class="require">&nbsp;*</span></p>
                    <input (input)="checkValidPhone($event.target.value)" (focusout)="focusOut()"  nz-input formControlName="phoneNumber"
                        maxlength="11" />
                    <div *ngIf="!isNumber && !f.phoneNumber.valid && (f.phoneNumber.dirty ||f.phoneNumber.touched)">
                        <div class="require"
                            *ngIf="f.phoneNumber.errors.required">Phone is
                            required</div>
                        <div class="require" *ngIf="f.phoneNumber.errors.pattern && f.phoneNumber.value.length < 9"> The phone must be at least 9
                            characters
                        </div>
                        <div class="require" *ngIf="f.phoneNumber.errors.pattern"> The phone number format is invalid
                        </div>
                    </div>
                    <div *ngIf="isNumber" class="require">
                        The phone number format is invalid
                    </div>
                </div>
            </div>
        </div>
        <div class="pdTop">
            <p>Address</p>
            <input (input)="blockSpace()" (focusout)="focusOut()" maxlength="200" nz-input formControlName="address" />
        </div>
        <div class="pdTop">
            <p>Position</p>
            <input (input)="blockSpace()" (focusout)="focusOut()"  maxlength="100" nz-input formControlName="position" />
        </div>
        <div class="pdTop">
            <p>Role<span class="require">&nbsp;*</span></p>
            <nz-select class="role" [nzMaxTagPlaceholder]="" nzMode="multiple" nzPlaceHolder="Please select"
                style="width: 100%;" [nzDisabled]="true" formControlName="roleId">
                <nz-option *ngFor="let item of listRoleUser" [nzLabel]="item.roleName" [nzValue]="item.roleId">
                </nz-option>
            </nz-select>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="pdTop">
                    <p>Level<span class="require">&nbsp;*</span></p>
                    <nz-select [nzDisabled]="true" id="level" class="w-100" formControlName="level">
                        <nz-option nzValue="1" nzLabel="LEVEL_1"></nz-option>
                        <nz-option nzValue="2" nzLabel="LEVEL_2"></nz-option>
                        <nz-option nzValue="3" nzLabel="LEVEL_3"></nz-option>
                        <nz-option nzValue="4" nzLabel="LEVEL_4"></nz-option>
                        <nz-option nzValue="5" nzLabel="LEVEL_5"></nz-option>
                        <nz-option nzValue="6" nzLabel="LEVEL_6"></nz-option>
                        <nz-option nzValue="7" nzLabel="LEVEL_7"></nz-option>
                        <nz-option nzValue="8" nzLabel="LEVEL_8"></nz-option>
                        <nz-option nzValue="9" nzLabel="LEVEL_9"></nz-option>
                        <nz-option nzValue="10" nzLabel="LEVEL_10"></nz-option>
                    </nz-select>

                </div>
            </div>
            <div class="col-md-6">
                <div class="pdTop">
                    <p>Status<span class="require">&nbsp;*</span></p>
                    <nz-select nzShowSearch formControlName="status" nzAllowClear [nzPlaceHolder]="statusShow()"
                        style="width: 100%;" [nzDisabled]="true">
                        <nz-option nzLabel="Active" nzValue="1"></nz-option>
                        <nz-option nzLabel="Inactive" nzValue="0"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
        <div class="pdTop">
            <p>Created at</p>
            <input nz-input value="{{user.createdAt}}" [disabled]="true" />
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="pdTop">
                    <p>Current avatar</p>
                    <img class="img" [src]="user.avatar || 'assets/images/avatar-default.jpg'">
                </div>
            </div>
            <div class="col-md-8">
                <div class="pdTop">
                    <p>Avatar</p>
                    <div class="row">
                        <div class="col-md-9">
                            <label class="labelInputFile">{{filePathShow}}<input type="file" style="display: none;"
                                    (change)="onChange($event)"></label>
                        </div>
                        <div class="col-md-3">
                            <label class="labelFile">Browse<input type="file" style="display: none;"
                                    (change)="onChange($event)"></label>
                        </div>

                        <div class="require" style="padding-left: 15px;" *ngIf="errorSelectImg">
                            The image format is invalid
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="row" style="padding-top: 30px;">
            <div class="col-md-6">
                <button type="button" class="btn btn-primary" style="margin-left: 80%;"
                    (click)="editUser()">Update</button>
            </div>
            <div class="col-md-6">
                <button type="button" class="btn btn-dark" style="margin-right: 20%;"
                    (click)="closeModal()">Cancel</button>

            </div>
        </div>
    </form>
</div>