import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UserInfo} from '../model/user-info/user-info.model';
import {User} from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  mainUrl = '/api/user/user';
  isUsernameExistUrl = '/check-username-exist';
  isEmailExistUrl = '/check-email-exist';
  urlFindUserById = '/api/user/user/findUserById';
  getIdByUserNameUrl = '/get-id-by-username';
  getUserTypeAndParentUser = '/getUserTypeAndParent';
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient) {
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  checkIfUsernameExists(username: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.isUsernameExistUrl}`, {params: {username}});
  }
  checkIfemailExists(email: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.isEmailExistUrl}`, {params: {email}});
  }
  getIdByUserName(): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.getIdByUserNameUrl}`);
  }
  findUserById(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.urlFindUserById}`, {params: {userId}});
  }
  getUserTypeAndParentById(userId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.mainUrl}${this.getUserTypeAndParentUser}`, {params: {userId}});
  }
}
