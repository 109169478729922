import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad} from '@angular/router';
import { Observable } from 'rxjs';
import {User} from "../../model/user";
import {Constants} from "../../theme/shared/common/constants";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanLoad {
  userInfo: User;

  constructor(private router: Router) {
  }

  canLoad(): boolean {
    let isAdmin = false;
    const userInfoTemp = localStorage.getItem('USER_INFO');
    if (userInfoTemp) {
      this.userInfo = JSON.parse(userInfoTemp);
      if (this.userInfo.userType === Constants.ADMIN_TYPE || this.userInfo.userType == Constants.ADMINSYS_TYPE) {
        isAdmin = true;
      } else if (this.userInfo.userType === Constants.ADV_TYPE) {
        isAdmin = false;
        this.router.navigate(['/dashboard/analytics']);
      }
    }
    return isAdmin;
  }
}
