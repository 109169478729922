import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AuthService} from '../../auth-services/auth.service';
import {Router} from '@angular/router';
import {User} from '../../../model/user';
import {AppParamService} from '../../../services/app-param/app-param.service';
import {AppParamModel} from '../../../model/app-param/app-param.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit {
  passwordTouched: boolean;
  emailTouched: boolean;
  passwordDirty: boolean;
  emailDirty: boolean;
  submitted = false;
  isLogin = false;
  isLoginFail = false;
  isLoginSuccess = false;
  userObj: User = {
    username: '',
    password: ''
  };
  paramCode = 'IMGLOGO';
  builderImg = '';
  isLoading: boolean;
  listOfData: string;
  hide = true;
  password;
  show = false;

  // tslint:disable-next-line:max-line-length
  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private appParamService: AppParamService) {
  }

  getUrlImage() {
    this.appParamService.getImageByCode(this.paramCode).subscribe(next => {
      this.builderImg = next.responseData[0].paramValue;
    });
  }

  ngOnInit() {
    this.getUrlImage();
    this.password = 'password';
  }

  isValidForm(): boolean {
    let valid: boolean;
    if (!this.userObj.username || !this.userObj.password) {
      valid = false;
    } else if (this.userObj.username && this.userObj.password) {
      valid = true;
    }
    return valid;
  }

  login() {
    this.submitted = true;
    this.isLoginFail = false;
    const user: User = {
      username: this.userObj.username.trim(),
      password: this.userObj.password,
    };
    const valid: boolean = this.isValidForm();
    if (!valid) {
      return;
    }
    this.isLoading = true;
    this.authService.login(
      user
    )
      .subscribe(success => {
        if (success) {
          this.isLogin = true;
          this.isLoginSuccess = true;
        } else {
          this.isLogin = false;
        }
      }, error => {
        setTimeout(() => {
          this.isLoading = false;
          this.isLoginFail = true;
        }, 3000);
      }, () => {
        // goi o day
        if (this.isLoginSuccess) {
          this.isLoading = false;
          this.router.navigate(['/dashboard/analytics']);
        } else {
          setTimeout(() => {
            this.isLoading = false;
            this.isLoginFail = true;
          }, 3000);
        }
      });
  }

  logValue($event) {
    this.emailDirty = true;
    this.isValidForm();
    this.userObj.username = $event.target.value;
  }

  logValuePass($event) {
    this.passwordDirty = true;
    this.isValidForm();
    this.userObj.password = $event.target.value;
  }

  check() {}

  blurEmailEvent($event: FocusEvent) {
    this.emailTouched = true;
    this.isValidForm();
  }

  blurPassEvent($event: FocusEvent) {
    this.passwordTouched = true;
    this.isValidForm();
  }

  hidePassword() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}
