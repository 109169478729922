import { Injectable } from '@angular/core';
import { group } from "@angular/animations";
import { User } from "../../../../model/user";
import { Constants } from '../../../../theme/shared/common/constants'

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItemsAdmin = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home',
        breadcrumbs: true
      },
      {
        id: 'adv',
        title: 'Adv Management',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'adv',
            title: 'Campaign',
            type: 'item',
            url: '/page/adv-campaign'
          }
        ]
      },
      {
        id: 'brand',
        title: 'Brand Management',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'brand',
            title: 'Company Detail',
            type: 'item',
            url: '/page/brand-company-detail',
            breadcrumbs: true
          }
        ]
      },
      {
        id: 'publisher',
        title: 'Publisher Management',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'publisher',
            title: 'My Publisher',
            type: 'item',
            url: '/page/my-publisher',
            breadcrumbs: true,
            children: {
              id: 'publisher',
              title: 'My Publisher',
              type: 'item',
              url: '/page/my-publisher',
              breadcrumbs: true
            }
          }
        ]
      },
      {
        id: 'report',
        title: 'Report',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'campaign-report',
            title: 'Campaign Report',
            type: 'item',
            url: '/page/report/campaign-report',
            breadcrumbs: true
          },
          {
            id: 'conversion-report',
            title: 'Conversion Report',
            type: 'item',
            url: '/page/report/conversion-report',
            breadcrumbs: true
          },
          {
            id: 'publisher-report',
            title: 'Publisher Report',
            type: 'item',
            url: '/page/report/publisher-report',
            breadcrumbs: true
          }
        ]
      },
      {
        id: 'user',
        title: 'User Management',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'user-s',
            title: 'User',
            type: 'item',
            url: '/page/user-management/user',
          },
        ]
      }
    ]
  },
  {
    id: 'admin',
    title: 'Admin Config',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'admin-child',
        title: 'Admin Config',
        type: 'collapse',
        icon: 'feather icon-layers',
        children: [
          {
            id: 'role',
            title: 'Role',
            type: 'item',
            url: '/admin/role'
          },
          {
            id: 'app-param',
            title: 'App Param',
            type: 'item',
            url: '/admin/app-param'
          },
        ]
      }
    ]
  },
  {
    id: 'pincode-management',
    title: 'Pincode Management',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'pincode-child',
        title: 'Pincode Management',
        type: 'collapse',
        icon: 'feather icon-layers',
        children: [
          {
            id: 'warehouse',
            title: 'Warehouse',
            type: 'item',
            url: '/page/pincode-management/warehouse'
          },
          {
            id: 'pincode',
            title: 'Pincode',
            type: 'item',
            url: '/page/pincode-management/pincode'
          }
        ]
      }
    ]
  }
];
const NavigationItemsAdv = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home',
        breadcrumbs: true
      },
      {
        id: 'adv',
        title: 'Adv Management',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'adv',
            title: 'Campaign',
            type: 'item',
            url: '/page/adv-campaign',
            breadcrumbs: false
          }
        ]
      },
      {
        id: 'brand',
        title: 'Brand Management',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'brand',
            title: 'Company Detail',
            type: 'item',
            url: '/page/brand-company-detail',
            breadcrumbs: true
          }
        ]
      },
      {
        id: 'publisher',
        title: 'Publisher Management',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'publisher',
            title: 'My Publisher',
            type: 'item',
            url: '/page/my-publisher',
            breadcrumbs: true
          }
        ]
      },
      {
        id: 'report',
        title: 'Report',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'campaign-report',
            title: 'Campaign Report',
            type: 'item',
            url: '/page/report/campaign-report',
            breadcrumbs: true
          },
          {
            id: 'conversion-report',
            title: 'Conversion Report',
            type: 'item',
            url: '/page/report/conversion-report',
            breadcrumbs: true
          },
          {
            id: 'publisher-report',
            title: 'Publisher Report',
            type: 'item',
            url: '/page/report/publisher-report',
            breadcrumbs: true
          }
        ]
      },
      {
        id: 'user',
        title: 'User Management',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
            id: 'user-s',
            title: 'User',
            type: 'item',
            url: '/page/user-management/user',
          }
        ]
      }

    ]
  }
];

@Injectable()
export class NavigationItem {
  user: User;

  public get() {
    let navigationItemsList: any[] = [];
    const userInfo = localStorage.getItem('USER_INFO');
    if (userInfo) {
      this.user = JSON.parse(userInfo);
      if (this.user.userType === Constants.ADMIN_TYPE || this.user.userType === Constants.ADMINSYS_TYPE) {
        console.log('admin');
        navigationItemsList = NavigationItemsAdmin;
      } else if (this.user.userType === Constants.ADV_TYPE) {
        console.log('adv');
        navigationItemsList = NavigationItemsAdv;
      }
    } else {
      navigationItemsList = [];
    }
    return navigationItemsList;
  }
}
