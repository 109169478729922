import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, OnChanges {
  @Input() isVisible: boolean;
  @Input() data: any;
  @Output() isClose = new EventEmitter<any>();
  model: any = {};
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && changes.data) {
      if (!this.data.profiles) {
        this.data.profiles = {};
      }
      this.model = this.data;
      console.log('data ======>', this.data);
    }
  }
  ngOnInit(): void {
  }
  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isClose.emit(false);
  }


}
